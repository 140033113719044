/* eslint-disable react/no-unescaped-entities */

import React from "react";
import { Container } from "react-bootstrap";
import { Layout, Seo } from "../components";

const PrivacyPolicyPage = (props) => (
  <Layout {...props}>
    <Seo location="privacy-policy" title="seoPrivacyPolicyPageTitle" />
    <Container fluid="lg" className="py-4 py-md-5">
      <h1>Privacy policy</h1>
      <p>
        This privacy policy has been compiled to better serve those who are
        concerned with how their 'Personally identifiable information' (PII) is
        being used online. PII, as used in US privacy law and information
        security, is information that can be used on its own or with other
        information to identify, contact, or locate a single person, or to
        identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect
        or otherwise handle your Personally Identifiable Information in
        accordance with our website.
      </p>
      <p>
        <b>
          What personal information do we collect from the people that visit our
          blog, website or app?
        </b>
      </p>
      <p>
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your name, email address or other details to help you
        with your experience.
      </p>
      <p>
        <b>When do we collect information?</b>
      </p>
      <p>
        We collect information from you when you register on our site, fill out
        a form or enter information on our site.
      </p>
      <p>
        <b>How do we use your information?</b>
      </p>
      <p>
        We may use the information we collect from you when you register, make a
        purchase, sign up for our newsletter, respond to a survey or marketing
        communication, surf the website, or use certain other site features in
        the following ways:
      </p>
      <p>
        {" "}
        • To personalize user's experience and to allow us to deliver the type
        of content and product offerings in which you are most interested.
      </p>
      <p>
        {" "}
        • To send periodic emails regarding your order or other products and
        services.
      </p>

      <p>
        <b>How do we protect visitor information?</b>
      </p>
      <p>
        We do not use vulnerability scanning and/or scanning to PCI standards.
      </p>
      <p>We do not use Malware Scanning.</p>
      <p>
        Your personal information is contained behind secured networks and is
        only accessible by a limited number of persons who have special access
        rights to such systems, and are required to keep the information
        confidential. In addition, all sensitive/credit information you supply
        is encrypted via Secure Socket Layer (SSL) technology.
      </p>
      <p>
        We implement a variety of security measures when a user enters, submits,
        or accesses their information to maintain the safety of your personal
        information.
      </p>
      <p>
        All transactions are processed through a gateway provider and are not
        stored or processed on our servers.
      </p>

      <p>
        <b>Do we use 'cookies'?</b>
      </p>
      <p>We do not use cookies for tracking purposes</p>
      <p>
        You can choose to have your computer warn you each time a cookie is
        being sent, or you can choose to turn off all cookies. You do this
        through your browser (like Internet Explorer) settings. Each browser is
        a little different, so look at your browser's Help menu to learn the
        correct way to modify your cookies.
      </p>
      <p>
        If you disable cookies off, some features will be disabled that make
        your site experience more efficient and some of our services will not
        function properly.
      </p>
      <p>However, you can still place orders.</p>

      <p>
        <b>Third Party Disclosure</b>
      </p>
      <p>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information unless we provide you with advance
        notice. This does not include website hosting partners and other parties
        who assist us in operating our website, conducting our business, or
        servicing you, so long as those parties agree to keep this information
        confidential. We may also release your information when we believe
        release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others' rights, property, or safety.{" "}
      </p>
      <p>
        However, non-personally identifiable visitor information may be provided
        to other parties for marketing, advertising, or other uses.
      </p>

      <p>
        <b>Third party links</b>
      </p>
      <p>
        We do not include or offer third party products or services on our
        website.
      </p>

      <p>
        <b>Google</b>
      </p>
      <p>
        Google's advertising requirements can be summed up by Google's
        Advertising Principles. They are put in place to provide a positive
        experience for users.
        https://support.google.com/adwordspolicy/answer/1316548?hl=en{" "}
      </p>
      <p>We use Google AdSense Advertising on our website.</p>
      <p>
        Google, as a third party vendor, uses cookies to serve ads on our site.
        Google's use of the DART cookie enables it to serve ads to our users
        based on their visit to our site and other sites on the Internet. Users
        may opt out of the use of the DART cookie by visiting the Google ad and
        content network privacy policy.
      </p>
      <p>We have implemented the following:</p>
      <p> • Demographics and Interests Reporting</p>
      <p>
        We along with third-party vendors, such as Google use first-party
        cookies (such as the Google Analytics cookies) and third-party cookies
        (such as the DoubleClick cookie) or other third-party identifiers
        together to compile data regarding user interactions with ad
        impressions, and other ad service functions as they relate to our
        website.
      </p>

      <p>
        <b>Opting out:</b>
      </p>
      <p>
        Users can set preferences for how Google advertises to you using the
        Google Ad Settings page. Alternatively, you can opt out by visiting the
        Network Advertising initiative opt out page or permanently using the
        Google Analytics Opt Out Browser add on.
      </p>

      <p>
        <b>Sendinblue</b>
      </p>
      <p>
        We use Sendinblue as our marketing platform. By subscribing to any of
        the newsletters, you acknowledge that the information you provided will
        be transferred to Sendinblue for processing in accordance with their
        terms of use that can be found here:
        https://www.sendinblue.com/legal/termsofuse/{" "}
      </p>

      <p>
        <b>California Online Privacy Protection Act</b>
      </p>
      <p>
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law's reach
        stretches well beyond California to require a person or company in the
        United States (and conceivably the world) that operates websites
        collecting personally identifiable information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals with whom it is being
        shared, and to comply with this policy. - See more at:
        http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
      </p>

      <p>
        <b>According to CalOPPA we agree to the following:</b>
      </p>
      <p>Users can visit our site anonymously</p>
      <p>
        Once this privacy policy is created, we will add a link to it on our
        home page, or as a minimum on the first significant page after entering
        our website.
      </p>
      <p>
        Our Privacy Policy link includes the word 'Privacy', and can be easily
        be found on the page specified above.
      </p>
      <p>Users will be notified of any privacy policy changes:</p>
      <p> • On our Privacy Policy Page</p>
      <p>Users are able to change their personal information:</p>
      <p> • By logging in to their account</p>

      <p>
        <b>How does our site handle do not track signals?</b>
      </p>
      <p>
        We honor do not track signals and do not track, plant cookies, or use
        advertising when a Do Not Track (DNT) browser mechanism is in place.
      </p>

      <p>
        <b>Does our site allow third party behavioral tracking?</b>
      </p>
      <p>
        It's also important to note that we do not allow third party behavioral
        tracking
      </p>

      <p>
        <b>COPPA (Children Online Privacy Protection Act)</b>
      </p>
      <p>
        When it comes to the collection of personal information from children
        under 13, the Children's Online Privacy Protection Act (COPPA) puts
        parents in control. The Federal Trade Commission, the nation's consumer
        protection agency, enforces the COPPA Rule, which spells out what
        operators of websites and online services must do to protect children's
        privacy and safety online.
      </p>
      <p>We do not specifically market to children under 13.</p>

      <p>
        <b>Fair Information Practices</b>
      </p>
      <p>
        The Fair Information Practices Principles form the backbone of privacy
        law in the United States and the concepts they include have played a
        significant role in the development of data protection laws around the
        globe. Understanding the Fair Information Practice Principles and how
        they should be implemented is critical to comply with the various
        privacy laws that protect personal information.
      </p>
      <p>
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </p>
      <p>We will notify the users via email</p>
      <p> • Within 7 business days</p>
      <p>
        We also agree to the individual redress principle, which requires that
        individuals have a right to pursue legally enforceable rights against
        data collectors and processors who fail to adhere to the law. This
        principle requires not only that individuals have enforceable rights
        against data users, but also that individuals have recourse to courts or
        a government agency to investigate and/or prosecute non-compliance by
        data processors.
      </p>

      <p>
        <b>CAN SPAM Act</b>
      </p>
      <p>
        The CAN-SPAM Act is a law that sets the rules for commercial email,
        establishes requirements for commercial messages, gives recipients the
        right to have emails stopped from being sent to them, and spells out
        tough penalties for violations.
      </p>
      <p>We collect your email address in order to:</p>
      <p>To be in accordance with CANSPAM we agree to the following:</p>
      <p>
        If at any time you would like to unsubscribe from receiving future
        emails, you can email us at hello@yearcompass.com and we will promptly
        remove you from ALL correspondence.
      </p>

      <p>
        <b>Contacting Us</b>
      </p>
      <p>
        If there are any questions regarding this privacy policy you may contact
        us using the information below.
      </p>
      <p>http://yearcompass.com</p>
      <p>Horanszky utca 13.</p>
      <p>Budapest, Budapest 1085</p>
      <p>Hungary</p>
      <p>hello@yearcompass.com</p>

      <p>Last Edited on 2016-01-20</p>
    </Container>
  </Layout>
);

export default PrivacyPolicyPage;
